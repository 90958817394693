import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = [
    'commentContainer',
    'radioButton',
    'submitButton',
    'ccExpired',
  ];

  connect() {
    super.connect();
    this.validateForm();
  }

  promoUpdate() {
    const promoCode = document.getElementById('promo_code').value;
    if (!!promoCode) {
      this.callStimulusAction({
        cb: () => {
          this.stimulate(
            'Store::MembersPortal::PromoUpdateReflex#update',
            promoCode,
          );
        },
      });
    }
  }

  select(event) {
    const value = event.target.value;

    this.radioButtonTargets.forEach((radioInput) => {
      const isActive = radioInput.value === value;

      const label = radioInput.closest('label');
      if (isActive) {
        label.classList.add('bg-black-charcoal', 'text-white');
        label.classList.remove('bg-white', 'text-black');
      } else {
        label.classList.add('bg-white', 'text-black');
        label.classList.remove('bg-black-charcoal', 'text-white');
      }
    });

    if (value) {
      this.commentContainerTarget.classList.remove('hidden');
      this.commentContainerTarget
        .querySelector('textarea')
        .setAttribute('required', 'required');

      if (value === 'no') {
        this.commentContainerTarget.querySelector('textarea').value =
          'No, nothing has changed in my medical history or medications.';
      } else {
        this.commentContainerTarget.querySelector('textarea').value = '';
      }
    } else {
      this.commentContainerTarget.classList.add('hidden');
      this.commentContainerTarget
        .querySelector('textarea')
        .removeAttribute('required');
    }
  }

  validateForm() {
    const hasValidCC = this.hasValidCreditCard();
    const radioSelected = this.radioButtonTargets.some(
      (input) => input.checked,
    );

    if (hasValidCC && radioSelected) {
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', 'true');
    }
  }

  hasValidCreditCard() {
    const ccExpired = this.ccExpiredTarget.value === 'true';
    return !ccExpired;
  }
}
