import ApplicationController from '../../application_controller';
import { scrollToElement } from '../../utilities/scrolling';
import { DropInInitializer } from '../../shared/dropin_initializer';

export default class extends ApplicationController {
  static targets = [
    'address1',
    'address2',
    'city',
    'stateId',
    'zipcode',
    'form',
    'submitBtn',
    'addressToggle',
    'dropInContainer',
    'paymentMethodNonce',
  ];

  validStates = this.stateIdTarget.dataset.validStates;

  connect() {
    super.connect();
    this.addStoredFieldValues();
    document.addEventListener('store-checkout-address-rewire', this.rewire.bind(this));
    document.addEventListener('store-checkout-address-validation-bypass', this.bypassValidation.bind(this));
    document.addEventListener('store-checkout-address-validation-enableSubmit', this.enableSubmit.bind(this));
    document.addEventListener('store-checkout-address-validation-display-address-errors', this.displayAddressErrors.bind(this));
    document.addEventListener('modalIsClosed', this.handleCloseAndEnablePayment.bind(this));

    if (this.hasAddressToggleTarget) {
      this.addressToggleTarget.addEventListener('change', this.loadAddressForm.bind(this));

      // Trigger loadAddressForm on page load
      document.addEventListener('DOMContentLoaded', () => {
        const { checked } = this.addressToggleTarget;
        if (checked) {
          // Manually call loadAddressForm with a synthetic event
          const event = new Event('change', { bubbles: true, cancelable: true });
          this.addressToggleTarget.checked = checked; // Ensure it reflects the correct checked state
          this.addressToggleTarget.dispatchEvent(event); // Dispatch the event to trigger the function
        }
      });
    }
    this.addInputEventListeners();
    this.toggleButtonEnabled();

    if (this.hasDropInContainerTarget) {
      this.checkoutClientToken = this.dropInContainerTarget.dataset.checkoutClientToken;
      DropInInitializer.initializeDropIn(
        this,
        this.submitBtnTarget,
        this.checkoutClientToken,
        this.toggleButtonEnabled.bind(this),
        this.dropInContainerTarget.dataset.orderAmount,
        'vault',
        this.dropInContainerTarget.dataset.googlePayMerchantId,
        'Merchant',
      );
    }
  }
  disconnect() {
    super.disconnect();
    document.removeEventListener('store-checkout-address-rewire', this.rewire.bind(this));
    document.removeEventListener('store-checkout-address-validation-bypass', this.bypassValidation.bind(this));
    document.removeEventListener('store-checkout-address-validation-enableSubmit', this.enableSubmit.bind(this));
    document.removeEventListener('store-checkout-address-validation-display-address-errors', this.displayAddressErrors.bind(this));
    document.removeEventListener('modalIsClosed', this.handleCloseAndEnablePayment.bind(this));
    if (this.hasAddressToggleTarget) {
      this.addressToggleTarget.removeEventListener('change', this.loadAddressForm.bind(this));
    }
    this.removeInputEventListeners();
    delete window.storeCheckoutSkipAddressLookup;
    delete window.storeCheckoutValidatedCurrentValues;
  }

  rewire() {
    this.removeInputEventListeners();
    this.addInputEventListeners();
    this.toggleButtonEnabled();
  }

  submitForm(event) {
    if (event) event.preventDefault();
    this.disableSubmitButton();
    DropInInitializer.disableChoosePayment(this);

    if (!this.addressValuesPresent() || window.storeCheckoutSkipAddressLookup) {
      this.formTarget.removeAttribute('data-action');
      this.formTarget.submit();
    } else {
      this.removeBypassedHiddenField();
      this.validateAddress();
      window.storeCheckoutValidatedCurrentValues = true;
      this.address1Target.parentNode.querySelector('p.text-error').innerHTML = '';
    }
  }

  addressValuesPresent() {
    return this.address1Target.value && this.cityTarget.value && this.stateIdTarget.value && this.zipcodeTarget.value;
  }

  validateAddress() {
    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Store::Cart::CheckoutAddressReflex#validate',
          this.address1Target.value,
          this.address2Target.value,
          this.cityTarget.value,
          this.stateIdTarget.value,
          this.zipcodeTarget.value,
          window.storeCheckoutValidatedCurrentValues || false,
        );
      },
    });
  }

  loadAddressForm(event) {
    event?.preventDefault();
    this.removeInputEventListeners();
    const { checked } = event.currentTarget;
    this.callStimulusAction({
      cb: () => {
        this.stimulate('Store::Cart::CheckoutAddressReflex#toggle_address', null, checked);
      },
    });
    this.addInputEventListeners();
  }

  bypassValidation(event) {
    const { logSkip } = event.detail;
    if (logSkip) this.addBypassedHiddenField();
    window.storeCheckoutSkipAddressLookup = true;
    this.submitForm();
  }

  addBypassedHiddenField() {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'checkout_form[skip_address_lookup]');
    input.setAttribute('value', 'true');
    input.setAttribute('id', 'skip_address_lookup_field');
    this.formTarget.appendChild(input);
  }

  removeBypassedHiddenField() {
    const hiddenField = document.getElementById('skip_address_lookup_field');
    if (hiddenField) hiddenField.remove();
  }

  enableSubmit() {
    scrollToElement(this.address1Target.parentNode);
    this.toggleButtonEnabled();
    DropInInitializer.enableChoosePayment(this);
  }

  clearRepeatAddressValidation() {
    window.storeCheckoutValidatedCurrentValues = false;
    this.toggleButtonEnabled();
  }

  formInputs() {
    return Array.from(
      this.formTarget.querySelectorAll(
        'input[type=text]:not(#store_checkout_form_ship_address_attributes_address2, #bill_address_attributes_address2), option:checked, select',
      ),
    );
  }

  submitButtonCanBeEnabled() {
    return this.formInputs().every((input) => input.value) && this.serviceableStateSelected();
  }

  toggleButtonEnabled() {
    if (this.submitButtonCanBeEnabled()) {
      this.enableSubmitButton();
    } else {
      this.disableSubmitButton();
    }
  }

  enableSubmitButton() {
    if (this.hasSubmitBtnTarget) {
      this.submitBtnTarget.removeAttribute('disabled');
      this.submitBtnTarget.classList.remove('cursor-not-allowed');
    }
    DropInInitializer.enableChoosePayment(this);
  }

  disableSubmitButton() {
    if (this.hasSubmitBtnTarget) {
      this.submitBtnTarget.setAttribute('disabled', 'disabled');
      this.submitBtnTarget.classList.add('cursor-not-allowed');
    }
  }

  addInputEventListeners() {
    this.stateIdTarget.addEventListener('change', this.validateServiceableState.bind(this));
    this.formInputs().forEach((input) => {
      input.addEventListener('change', this.toggleButtonEnabled.bind(this));
      input.addEventListener('input', this.toggleButtonEnabled.bind(this));
    });
  }

  removeInputEventListeners() {
    this.formInputs().forEach((input) => {
      input.removeEventListener('change', this.toggleButtonEnabled.bind(this));
      input.removeEventListener('input', this.toggleButtonEnabled.bind(this));
    });
    this.stateIdTarget.removeEventListener('change', this.validateServiceableState.bind(this));
  }

  storeFieldValues(event) {
    const element = event.currentTarget;
    localStorage.setItem(element.id, element.value);
  }

  addStoredFieldValues() {
    this.formFieldsForLocalstorage().forEach((target) => {
      const value = localStorage.getItem(target.id);
      if (value) target.value = value;
    });
  }

  formFieldsForLocalstorage() {
    return [this.address1Target, this.address2Target, this.cityTarget, this.zipcodeTarget, this.stateIdTarget];
  }

  displayAddressErrors(event) {
    const parentOfFieldElement = this.address1Target.parentNode;
    const { errorMessage } = event.detail;

    scrollToElement(parentOfFieldElement);
    parentOfFieldElement.querySelector('p.text-error').innerHTML = errorMessage;
  }

  validateServiceableState() {
    if (this.serviceableStateSelected()) {
      this.toggleButtonEnabled();
    } else {
      this.disableSubmitButton();

      this.callStimulusAction({
        cb: () => {
          this.stimulate('Store::Cart::CheckoutAddress#unserviceable_state');
        },
      });
    }
  }

  serviceableStateSelected() {
    if (!this.stateIdTarget.value) return false;

    const { validStates } = this.stateIdTarget.dataset;
    return validStates.includes(this.stateIdTarget.value);
  }

  handleCloseAndEnablePayment() {
    this.toggleButtonEnabled();
  }
}
