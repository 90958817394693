import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static inputContainerClass = '.input-container';

  connect() {
    super.connect();
    this.initializeListeners();
  }

  getInputs() {
    return this.element.querySelectorAll('.store-input');
  }

  initializeListeners() {
    this.getInputs().forEach((input) => {
      this.filledInitialCheck();
      input.addEventListener('blur', () => this.updateFilledClass(input));
      input.addEventListener('keyup', () => this.updateFilledClass(input));
      input.addEventListener('keydown', () => this.updateFilledClass(input));
    });
  }

  updateFilledClass(input) {
    const parent = input.closest(this.constructor.inputContainerClass);
    this.filledInitialCheck();
  }

  filledInitialCheck() {
    const emailInput = document.querySelector('input#user_email');
    const fnameInput = document.querySelector('input#user_first_name');
    const lnameInput = document.querySelector('input#user_last_name');
    if (
      emailInput.value.trim() !== '' &&
      fnameInput.value.trim() !== '' &&
      lnameInput.value.trim() !== ''
    ) {
      const user_password = document.querySelector('input#user_password');
      user_password
        .closest(this.constructor.inputContainerClass)
        .classList.remove('!hidden');
    }
  }
}
