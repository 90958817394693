import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['consentAsked'];

  connect() {
    super.connect();
    document.addEventListener('BLOTOUT_CONSENT_GRANTED', this.onConsentGranted.bind(this));
    document.addEventListener('BLOTOUT_CONSENT_DENIED', this.onConsentDenied.bind(this));
    this.checkBlotoutConsent();
  }

  disconnect() {
    super.disconnect();
    document.removeEventListener('BLOTOUT_CONSENT_GRANTED', this.onConsentGranted.bind(this));
    document.removeEventListener('BLOTOUT_CONSENT_DENIED', this.onConsentDenied.bind(this));
  }

  onConsentGranted(event) {
    event.preventDefault();
    const { email, firstName, lastName } = event.detail;
    window.edgetag('consent', { all: true });
    // https://app.edgetag.io/docs/js#data
    window.edgetag('data', { email, firstName, lastName });
    window.edgetag('tag', 'Contact');
  }

  onConsentDenied(event) {
    event.preventDefault();
    window.edgetag('consent', { all: false });
    window.edgetag('tag', 'Contact');
  }

  checkBlotoutConsent() {
    if (this.consentAskedTarget.value === 'true') return;

    // Launch the Blotout consent modal
    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'UserDemographics::BlotoutConsent#render_consent_form'
        );
      },
    });
  }
}
