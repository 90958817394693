import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'flControl',
    'flTrigger'
  ];

  connect() {
    super.connect();
    const eventStr = this.flControlTarget?.dataset?.floodlightEventId ?? '';
    const shouldTrigger = (this.flControlTarget?.dataset?.floodlightShouldTrigger || this.getCookie()) ?? false;
    this.events = eventStr.split(',');
    this.idx = 0;
    if (shouldTrigger) {
      this.boundClick = this.click.bind(this);
      this.flTriggerTarget?.addEventListener('click', this.boundClick);
    }
  }

  click() {
    if (this.idx < this.events.length) {
      const evt = this.events[this.idx++];
      this.fireEvent(evt);
      if (this.idx === this.events.length) {
        this.flTriggerTarget?.removeEventListener('click', this.boundClick);
      }
    }
  }

  fireEvent(evt) {
    window.floodlight(evt);
    this.setCookie();
  }

  setCookie() {
    if (!document.cookie.includes('floodlight_tracked')) {
      // 30 minute cookie expiry, we don't need to track this for long
      const expires = new Date(Date.now() + 1000 * 60 * 30).toUTCString();
      document.cookie = `floodlight_tracked=true; path=/; expires=${expires}`;
    }
  }

  getCookie() {
    return document.cookie.includes('floodlight_tracked');
  }
}
