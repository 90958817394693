import dropin from 'braintree-web-drop-in';

export const DropInInitializer = {
  initializeDropIn(
    controller,
    submitButton,
    checkoutClientToken,
    enableSubmitButton,
    orderAmount,
    paypalFlow,
    googlePayMerchantId,
    applePayMerchantName,
  ) {
    if (!checkoutClientToken || checkoutClientToken === '') {
      return;
    }

    this.containerId = '#bt_drop_in';

    const dropinOptions = {
      vaultManager: true,
      preselectVaultedPaymentMethod: true,
      authorization: checkoutClientToken,
      container: this.containerId,
      card: {
        vault: {
          allowVaultCardOverride: true,
        },
      },
    };

    if (paypalFlow) {
      dropinOptions.paypal = {
        flow: paypalFlow,
        currency: 'USD',
        intent: 'authorize',
        buttonStyle: {
          shape: 'rect',
          color: 'blue',
          size: 'large',
        },
      };
    }

    if (applePayMerchantName) {
      dropinOptions.applePay = {
        displayName: applePayMerchantName,
        paymentRequest: {
          total: {
            label: 'Rugiet',
            amount: orderAmount,
          },
          requiredBillingContactFields: ['postalAddress'],
        },
      };
    }

    if (googlePayMerchantId) {
      dropinOptions.googlePay = {
        googlePayVersion: 2,
        merchantId: googlePayMerchantId,
        transactionInfo: {
          totalPriceStatus: 'ESTIMATED',
          totalPrice: orderAmount,
          currencyCode: 'USD',
        },
      };
    }

    dropin.create(
      {
        authorization: checkoutClientToken,
        container: this.containerId,
        ...dropinOptions,
      },
      (createErr, dropinInstance) => {
        if (createErr) {
          console.error('Error creating Drop-in instance:', createErr);
          return;
        }

        controller.dropinInstance = dropinInstance;

        submitButton.addEventListener('click', (event) => {
          event.preventDefault();

          dropinInstance.requestPaymentMethod((err, payload) => {
            if (err) {
              console.error('Error requesting payment method:', err);
              return;
            }

            controller.paymentMethodNonceTarget.value = payload.nonce;
            controller.submitForm();
          });
        });

        if (dropinInstance.isPaymentMethodRequestable()) {
          // This will be true if you generated the client token
          // with a customer ID and there is a saved payment method
          // available to tokenize with that customer.
          enableSubmitButton();
        }

        dropinInstance.on('paymentMethodRequestable', (event) => {
          switch (event.type) {
            case 'CreditCard':
              if (event.paymentMethodIsSelected) {
                enableSubmitButton();
              } else {
                controller.disableSubmitButton();
                dropinInstance.requestPaymentMethod((err, payload) => {
                  if (err) {
                    console.error(err);
                    return;
                  }
                });
              }
              break;
            case 'GooglePayCard':
            case 'AndroidPayCard':
            case 'ApplePayCard':
            case 'PayPalAccount':
              if (event.paymentMethodIsSelected) {
                enableSubmitButton();
              }
              break;
            default:
              break;
          }
        });

        dropinInstance.on('noPaymentMethodRequestable', () => {
          controller.disableSubmitButton();
        });
      },
    );
  },

  disableChoosePayment(controller) {
    if (controller.dropinInstance) {
      controller.dropinInstance.updateConfiguration({
        card: { flow: 'disabled' },
      });
      document.querySelector(this.containerId).style.pointerEvents = 'none';
      document.querySelector(this.containerId).style.opacity = '0.6';
    }
  },

  enableChoosePayment(controller) {
    if (controller.dropinInstance) {
      controller.dropinInstance.updateConfiguration({
        card: { flow: 'vault' },
      });
      document.querySelector(this.containerId).style.pointerEvents = 'auto';
      document.querySelector(this.containerId).style.opacity = '1';
    }
  },
};
